.apexcharts-xaxis-label,
.apexcharts-yaxis-label,
.apexcharts-title-text,
.apexcharts-yaxis-title-text,
.apexcharts-xaxis-title-text {
  fill: #fff; 
}
  

.apexcharts-legend-text {
  color: #fff; 
  fill:#fff;
}


.progress-bar {
  width: 100%; 
  height: 20px; 
  background: #f0f0f0; 
  display: inline-block;
  margin: 0 10px; 
  margin-top:10px;
}

.fill1 {
  width: 100%;
  background: #4CAF50; 
  text-align: center;
  color: black;
  font-weight: bold;
  transition: width 2s; 
}

.fill2 {
  width: 100%;
  background: red; 
  text-align: center;
  color: black;
  font-weight: bold;
  transition: width 2s; 
}

.fill3 {
  width: 100%;
  background: #ef843c; 
  text-align: center;
  color: black;
  font-weight: bold;
  transition: width 2s; 
}

.fill4 {
  width: 100%;
  background: #93A3B8; 
  text-align: center;
  color: black;
  font-weight: bold;
  transition: width 2s; 
}


.legend{
  display:flex;
  flex-direction: row;
  margin-top:30px;
}


.legend-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-right: 30px;
}

.legend-box {
  width: 15px;
  height: 15px;
  border: 1px solid #000;
  margin-right: 10px;
}

.company-coverage {
  background-color: #6eb5f9;
}

.cynomi-coverage {
  background-color: #2a63a8;
}

.total-controls {
  background-color: #e5e8f9;
}

.circle-container {
  position: relative;
  width: 600px;
  height: 400px;
}

.circle {
  position: absolute;
  border-radius: 50%;
}

.circle-label {
  position: absolute;
  text-align: center;
  font-weight: bold;
}

.circle-value {
  position: absolute;
  text-align: center;
}

.maplegend {
  display: flex;
  align-items: center;
  margin-bottom: 0px;
  font-family: Arial, sans-serif;
  color:#000;
  font-weight: bold;
  font-size: 13px;
}
.maplegenddot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 10px;
}


.azure { background-color: #FFBF00; }
.aws { background-color: #f2103f; }
.gcp { background-color: #57ba7e; color: white; }
.snowflake { background-color: #9fb3fe; color: white; }


.reviews-container {
  width: 100%;
  margin: 0 auto;
  margin-top: 0em;
  padding: 4px;
}

.inner {
  padding: 1em;
  overflow: hidden;
  position: relative;
  -webkit-border-radius: 4px; 
  -moz-border-radius: 4px; 
  border-radius: 4px; 
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.rating {
  float: left;
  width: 45%;
  margin-right: 5%;
  text-align: center;
}

.rating-num {
  color: #fff;
  font-size: 40px;
  font-weight: 100;
  line-height: 1em; 
}

.rating-stars {
  font-size: 20px;
  color: #E3E3E3;
  margin-bottom: .5em;
}
.rating-stars .active {
  color: #737373;
}

.rating-users {
  font-size: 14px;
  color:#fff
}

.histo {
  width: 100%;
  font-size: 13px;
  margin-top:30px;
align-self: center;
}

.histo-star {
  float: left;
  padding: 3px;
  width:100px;
  color:#fff;
}



.histo-rate {
  width: 100%;
  display: flex;
  flex-direction: row;
  clear: both;
}

.bar-block {
  margin-left: 5px;
  color: black;
  display: block;
  float: left;
  width: 80%;
  font-weight: bold;
  position: relative;
}

.MuiTableCell-body div{
  color:#fff;
}

.bar {
  padding: 4px;
  display: block;
}

.bar1 {
  padding: 4px;
  display: block;
}

#bar-five {
  width: 0;
  background-color: #01af50;
}

#bar-four {
  width: 0;
  background-color: #d8d8d8;
}

#bar-three {
  width: 0;
  background-color: #b80500;
}

#bar-two {
  width: 0;
  background-color: #228fab;
}


#bar-five1 {
  width: 0;
  background-color: #01af50;
}

#bar-four1 {
  width: 0;
  background-color: #d8d8d8;
}

#bar-three1 {
  width: 0;
  background-color: #b80500;
}

#bar-two1 {
  width: 0;
  background-color: #228fab;
}

.four .bar span{
  color:#000;
}

.four .bar1 span{
  color:#000;
}



.line .apexcharts-gridlines-horizontal,
.line .apexcharts-gridlines-vertical {
  display: none !important;
}


.complianceChecks {
  border-collapse: collapse;
  width: 100%;
}

.complianceChecks th,
.complianceChecks td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.complianceChecks th {
  background-color: #f2f2f2;
}

.complianceChecks .green-bar,
.complianceChecks .amber-bar,
.complianceChecks .red-bar {
  height: 20px;
  position: relative;
}

.complianceChecks .inner-bar {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
}

.complianceChecks .green-bar .inner-bar {
  background-color: lightgreen; 
}

.complianceChecks .amber-bar .inner-bar {
  background-color: lightcoral; 
}

.complianceChecks .red-bar .inner-bar {
  background-color: lightcoral; 
}

.complianceChecks .bar-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: black;
  font-weight: bold;
}

.complianceChecks th,td{
  font-size: 13px;
}

.complianceChecks th,td{
  width:16%
}


.circle {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  position: relative;
  background-color: #b9b3b3; 
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.donut {
  width: 80%;
  height: 80%;
  border-radius: 50%;
  position: absolute;
  top: 10%;
  left: 10%;
  background-color: white; 
  clip-path: circle(50%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.segment {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: 2px solid white;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.laws {
  background-color: #EFae90; 
  transform: rotate(0deg);
  clip-path: polygon(20% 20%, 0 0, 0 100%, 20% 100%);
}

.security {
  background-color: #8da2db; 
  transform: rotate(72deg);
  clip-path: polygon(20% 20%, 0 0, 0 100%, 20% 100%);
}

.industry-regulations {
  background-color: #b9b3b3; 
  transform: rotate(144deg);
  clip-path: polygon(60% 60%, 0 0, 0 100%, 50% 100%);
}

.text {
  color: white;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  position: absolute;
  transform: translate(-50%, -50%);
}



.progress-circle {
  width: 50px;
  height: 50px;
  position: relative;
}
.progress-circle svg {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.progress-circle text {
  font-size: 12px;
  dominant-baseline: middle;
  text-anchor: middle;
  fill: #000;
}
.progress-circle .progress-bg {
  fill: transparent;
  stroke: #f0f0f0;
  stroke-width: 4;
}
.progress-circle .progress-value {
  fill: transparent;
  stroke-width: 4;
  transform-origin: center;
  transform: rotate(-90deg);
  transition: stroke-dasharray 0.5s ease;
}

.progress-circle .progress-value.completed {
  stroke: #4fb277; 
}

.progress-circle .progress-value.on-track {
  stroke: #5957c3; 
}

.progress-circle .progress-value.delayed {
  stroke: #fb891d; 
}

.progress-circle .progress-value.at-risk {
  stroke: #ad3e65; 
}

.apexcharts-legend-text {
  color: #ffffff !important; 
}

.apexcharts-legend {
  color: #ffffff !important; 
}


.failedControlTableText{
  font-size: 0.2em;
  display:block
}

.apexcharts-tooltip {
  background-color: #ffffff; 
}

.apexcharts-tooltip-title,
.apexcharts-tooltip-text {
  color: #000000 !important; 
}

.bar-text{
  color:#fff !important;
  font-size: 13px;
}


.biggerWheel{
  display:flex;
}

.smallerWheel{
  display:none;
}

@media screen and (max-width: 1500px) {
  .biggerWheel{
    display:none;
  }
  
  .smallerWheel{
    display:flex;
  }
}



.psd3Tooltip,
text {
  pointer-events: none
}

.psd3Tooltip p,
text {
  font-family: sans-serif
}

text {
  font-size: 8px
}

g.arc path:hover {
  fill: orange
}

.psd3Tooltip {
  position: absolute;
  max-width: 150px;
  height: auto;
  padding: 10px;
  background-color: #fff;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  -webkit-box-shadow: 4px 4px 10px rgba(0, 0, 0, .4);
  -moz-box-shadow: 4px 4px 10px rgba(0, 0, 0, .4);
  box-shadow: 4px 4px 10px rgba(0, 0, 0, .4)
}

.psd3Hidden {
  display: none
}

.psd3Tooltip p {
  margin: 0;
  font-size: 16px;
  line-height: 20px
}

.apexcharts-legend-series{
  display: flex;
  flex-direction: row;
  align-items: center !important;
}

.apexcharts-legend-text{
  margin-top:5px;
}

.apexcharts-radialbar-hollow text{
  font-size: 18px !important; 
}

.findingsTab{
  width:42%;
  font-size: 15px;
  padding:20px;
}

.incidentCard2Text1{
  font-size: 25px;
}

.incidentCard2Text2{
  font-size: 17px;
}


@media screen and (max-width: 1300px) {

  .findingsTab{
    width:25%;  
    font-size: 10px;  
    text-align: center;
    padding:15px;
  }

  .incidentCard2Text1{
    font-size: 20px;
  }
  
  .incidentCard2Text2{
    font-size: 13px;
  }
}


@media screen and (max-width: 1500px) {

  .dataStoredMap{
  width:400px;
}

}

.customSection{
  height:460px;
}

@media screen and (max-width: 1500px) {

  .customSection{
    height:300px;
  }

}